<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">工资包</span>
        <img src="../../assets/right.png" alt />
        <span class="center">工资包列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ type === "add" ? "新增" : "编辑" }}工资包</span>
      </div>
    </div>
    <el-card>
      <div style="margin-bottom: 20px">
        <Divider :title="'基本信息'"></Divider>
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
          label-width="110px"
        >
          <el-form-item label="需求方名称" required>
            <el-input type="text" v-model="enterpriseName" placeholder="请输入需求方名称" disabled></el-input>
          </el-form-item>

          <el-form-item label="工资包名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>

          <el-form-item label="归属项目" prop="projectCode">
            <el-select
              v-model="ruleForm.projectCode"
              filterable
              placeholder="请选择归属项目"
              style="width: 100%"
              @change="change()"
            >
              <el-option
                v-for="item in projectData"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="工资包有效期" prop="serviceValidity">
            <el-date-picker
              type="datetime"
              placeholder="选择日期"
              v-model="ruleForm.serviceValidity"
              style="width: 100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              default-time="23:59:59"
            ></el-date-picker>
          </el-form-item>-->

          <el-form-item label="备注" prop="remark" style="width: 100%">
            <el-input type="textarea" v-model="ruleForm.remark"></el-input>
          </el-form-item>
          <Divider :title="'审批操作人员'" :tag="`相关人员在手机端处理`"></Divider>
          <el-row style="width: 100%" v-for="(item, i) in checkList" :key="i">
            <el-col :span="12">
              <el-form-item :label="item.name + (i + 1)" required style="width: 100%">
                <SelectUser :isChecker="false" :type="typeNew" v-model="item.value" :key="'k' + i"></SelectUser>
              </el-form-item>
            </el-col>
            <el-col
              :span="1"
              style="position: relative; top: 50px"
              v-if="type === 'add' || state === '3'"
            >
              <!-- <i
                class="el-icon-delete"
                style="color: red; font-size: 18px; cursor: pointer"
                @click="delItem(i)"
              ></i>-->
            </el-col>
          </el-row>
          <Divider :title="'复核操作人员'" :tag="`相关人员在电脑端处理`"></Divider>
          <el-row style="width: 100%">
            <el-col :span="12">
              <el-form-item label="复核人" required style="width: 100%">
                <SelectUser :type="typeNew" :key="'ysr'" v-model="checkerCode.value"></SelectUser>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 上传工资单 -->
        <Divider :title="'上传工资单'">
          <span slot="right">
            <el-upload
              v-if="type === 'add' || state === '3'"
              style="text-align: left;"
              class="upload-demo"
              action
              :multiple="false"
              :disabled="templateShow"
              :limit="1"
              :auto-upload="false"
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :on-change="importExcel"
              :file-list="uploadFiles"
            >
              <el-button size="small" type="text" :disabled="templateShow">点击上传</el-button>
            </el-upload>
          </span>
        </Divider>
        <div style="margin:10px;float:left">
          <span>工资单模板：</span>
          <el-select
            filterable
            reserve-keyword
            remote
            v-model="keywords"
            size="mini"
            placeholder="请选择"
            :remote-method="getTemplate"
            @change="templateChange"
          >
            <el-option
              :label="item.title"
              :value="item.code"
              v-for="(item, i) in templateList"
              :key="i"
            ></el-option>
          </el-select>
        </div>

        <!-- <el-upload
          v-if="type === 'add' || state === '3'"
          style="text-align: left; margin-bottom: 30px"
          class="upload-demo"
          action
          :multiple="false"
          :limit="1"
          :auto-upload="false"
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :on-change="importExcel"
          :file-list="uploadFiles"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <span style="margin-left: 12px">{{ fileName }}</span>
          <div slot="tip" class="el-upload__tip" style="font-size: 14px">
            工资单 只能上传execl文件,模板参照:
            <span @click="downExcel()" style="color: #409eff">工资单模板</span>
          </div>
        </el-upload>-->
        <el-table :data="tableData" border style="width: 100%" v-if="!templateShow">
          <!-- <el-table-column prop="userName" :label="templateDetail.name"></el-table-column>
          <el-table-column prop="idCardNumber" :label="templateDetail.idCardNumber"></el-table-column>
          <el-table-column prop="salary" :label="templateDetail.salary"></el-table-column>-->
          <el-table-column v-for="(item, index) in headerTitle" :key="index" :label="item">
            <template slot-scope="scope">{{ scope.row[item] }}</template>
          </el-table-column>
          <el-table-column label="人员查询结果">
            <template slot-scope="scope">
              <p
                style="margin: 0px; padding: 0px"
                v-if="scope.row.existError"
                class="font-color-danger"
              >用户不存在</p>
              <p
                style="margin: 0px; padding: 0px"
                v-if="scope.row.isAuthenticatedError"
                class="font-color-danger"
              >用户未认证</p>
              <p
                style="margin: 0px; padding: 0px"
                v-if="scope.row.hasBankCardError"
                class="font-color-danger"
              >用户银行卡信息有误</p>
              <p
                style="margin: 0px; padding: 0px"
                v-if="scope.row.hasMult"
                class="font-color-danger"
              >人员重复</p>

              <p
                style="margin: 0px; padding: 0px"
                v-if="scope.row.isSalaryError"
                class="font-color-danger"
              >金额有误</p>
              <p
                style="margin: 0px; padding: 0px"
                v-if="
                  !scope.row.existError &&
                  !scope.row.isAuthenticatedError &&
                  !scope.row.hasBankCardError &&
                  !scope.row.hasMult &&
                  !scope.row.isSalaryError
                "
                class="font-color-success"
              >用户信息正确</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="add_bottom">
        <el-button
          type="primary"
          size="small"
          style="margin-left: 20px"
          :disabled="!valid"
          @click="submitForm('ruleForm')"
        >保存</el-button>
        <el-button type="info" @click="goBack" size="small" plain>取消</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import XLSX from 'xlsx';
import { api } from '/src/api/base';
import moment from 'moment';
import * as _ from 'lodash';
export const projectInfo = api()('project.info.json');
export const salaryTaskAdd = api()('salaryTask.add.json');
export const salaryTaskEdit = api()('salaryTask.edit.json');
export const taskTamplateList = api()('taskTamplate.list.json');
export const salaryRegistrationCompare = api()(
  'salaryRegistration.compare.json'
);
export const salaryTaskDescribe = api()('salaryTask.describe.json');
export const salaryTaskRegistrationList = api()(
  'salaryTask.registration.list.json'
);
export const projectList = api()('project.list.json');

export default {
  components: {
    SelectUser: () => import('./selectUser.vue'),
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      loading: false,
      keywords: '',
      type: '',
      typeNew: '工资包',
      code: '',
      projectData: [],
      templateDetail: { selectTags: '' },
      // templateTags: [],
      project: '',
      headerTitle: [],
      enterpriseName: '',
      templateShow: true,
      ruleForm: {
        name: '',
        projectCode: '',

        remark: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入工资包名称', trigger: 'blur' },
        ],

        projectCode: [
          { required: true, message: '请选择归属项目', trigger: 'blur' },
        ],

        remark: [],
      },
      templateList: [],
      uploadFiles: [],
      state: '',
      fileName: '',
      transData: [],
      submitTransData: [],
      tableData: [],
      platformList: [],
      checkList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      checkerCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      valid: false,
    };
  },

  async created () {
    this.platformList = this.$route.params.$preload.platformList;
    this.type = this.$route.query.type;
    if (this.type === 'add') {
      // this.enterpriseName = localStorage.getItem('enterpriseName');
      this.getTemplate('')
      this.ruleForm.name = `${moment().format('YYYY-MM')}月工资包`;
    }
    if (this.type === 'edit') {
      this.code = this.$route.query.code;
      // this.enterpriseName = localStorage.getItem('enterpriseName');
      this.getDetail();
      this.getRegistrationList();
    }
    await this.projectList();
  },
  methods: {
    async getTemplate (keywords) {
      const params = {
        keywords: keywords,
        pageSize: 10,
        pageNumber: 1,
      };
      const res = await taskTamplateList(params)

      this.templateList = res.list

    },
    async templateChange () {
      this.templateDetail = this.templateList.filter(it => it.code === this.keywords)[0]
      this.headerTitle = this.templateDetail.selectTags.split(',')

      this.templateShow = false

    },
    async change () {

      const rs = await projectInfo({ code: this.ruleForm.projectCode });
      this.enterpriseName = rs.companyName
      let _checkList = [];
      rs.taskViewers.split(',').forEach((it) => {
        _checkList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.checkList = _checkList;
      this.checkerCode.value.subjectAccount = rs.taskReviewer;
    },
    async projectList () {
      const res = await projectList({
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,

      });
      this.projectData = res.list;
    },
    async getDetail () {
      const rs = await salaryTaskDescribe({
        code: this.code,
      });
      Object.keys(this.ruleForm).forEach((it) => {
        this.ruleForm[it] = rs[it];
      });
      this.state = rs.state;

      this.valid = true;

      let _checkList = [];

      rs.viewerCodes.split(',').forEach((it) => {
        _checkList.push({
          name: '审批人',
          value: {
            subjectAccount: it,
          },
        });
      });
      this.checkList = _checkList;
      this.checkerCode.value.subjectAccount = rs.reviewerCode;
    },
    async getRegistrationList () {
      const { list } = await salaryTaskRegistrationList({ code: this.code });
      this.tableData = list;

      console.log(this.tableData);
    },
    addChecker () {
      this.checkList.push({
        name: '审批人',
        value: {
          subjectAccount: '',
        },
      });
    },
    delItem (i) {
      if (this.checkList.length === 1) {
        return this.$message.error('至少保留一个审核人！');
      }
      this.checkList = this.checkList.filter((it, index) => index !== i);
    },
    goBack () {
      window.history.back(-1);
    },
    submitForm (formName) {
      // const checks = this.checkList
      //   .map((it) => it.value.subjectAccount)
      //   .join(',');
      this.$refs[formName].validate(async (valid) => {
        const params = {
          ...(this.type === 'edit' ? { code: this.code } : {}),
          ...this.ruleForm,
          taskTamplateCode: this.keywords,
          // approvers: checks,
          salaryItems: this.tableData,
          // checkerCode: this.checkerCode.value.subjectAccount,
        };
        if (valid) {
          // console.log(params, "=========================")
          if (this.type === 'add') {
            await salaryTaskAdd(params);
          }
          if (this.type === 'edit') {
            await salaryTaskEdit(params);
          }
          this.$refs[formName].resetFields();
          this.$router.push({
            path: '/system/task/list',
          });
        } else {
          return false;
        }
      });
    },
    formatDecimal (num, decimal) {
      num = num.toString()
      let index = num.indexOf('.')
      if (index !== -1) {
        num = num.substring(0, decimal + index + 1)
      } else {
        num = num.substring(0)
      }
      return parseFloat(num).toFixed(decimal)
    },

    // 导入表格
    formatChn (data) {
      return data.map((item) => {
        const t = {
          [this.templateDetail.name]: item[this.templateDetail.name].replace(/\s/g, ''),
          [this.templateDetail.idCardNumber]: item[this.templateDetail.idCardNumber].replace(/\s/g, ''),
          [this.templateDetail.salary]: item[this.templateDetail.salary].toString().includes('.') ? item[this.templateDetail.salary].toString().split('.')[1].length > 2 ? Math.trunc(item[this.templateDetail.salary] * 100) / 100 : item[this.templateDetail.salary] : item[this.templateDetail.salary],
        }
        const unpick = _.without(this.templateDetail.selectTags.split(','), this.templateDetail.name, this.templateDetail.idCardNumber, this.templateDetail.salary)

        unpick.forEach(n => t[n] = item[n])
        return t
      }
      )
    },
    formatEn (data) {
      return data.map((item) => {
        const t = {
          username: item[this.templateDetail.name].replace(/\s/g, ''),
          idCardNumber: item[this.templateDetail.idCardNumber].replace(/\s/g, ''),
          salary: item[this.templateDetail.salary].toString().includes('.') ? item[this.templateDetail.salary].toString().split('.')[1].length > 2 ? Math.trunc(item[this.templateDetail.salary] * 100) / 100 : item[this.templateDetail.salary] : item[this.templateDetail.salary],
        }
        const unpick = _.without(this.templateDetail.selectTags.split(','), this.templateDetail.name, this.templateDetail.idCardNumber, this.templateDetail.salary)

        unpick.forEach(n => t[n] = item[n])
        console.log(t, 3333);
        return t
      }
      )

    },
    async importExcel (param) {

      this.uploadFiles = [];
      this.fileName = param.userName;
      const arr = await this.getExcelHeaders(param)
      const arrNew = this.templateDetail.baseTags.split(',').filter(it => arr.includes(it))

      if (arrNew.length === this.templateDetail.baseTags.split(',').length) {
        this.file2Xce(param).then((item) => {
          if (item && item.length > 0) {
            if (item[0].sheet.length === 0) {
              return this.$message.error('传入表单无数据！');
            }

            if (item[0] && item[0].sheet && item[0].sheet.length) {
              // 校验传入表格字段

              const valid = item[0].sheet.every(
                (item) =>

                  item[this.templateDetail.name] &&
                  item[this.templateDetail.idCardNumber] &&
                  item[this.templateDetail.salary]
                  &&
                  !isNaN(item[this.templateDetail.salary])
                // && item[this.templateDetail.salary].toString().split(".")[1].length > 2
              );
              if (!valid) {
                return this.$message.error('请传入正确表格!');
              }

              console.log(item[0].sheet);
              // 把数据塞到表格预览

              this.submitTransData = this.formatEn(item[0].sheet)

              this.transData = this.formatChn(item[0].sheet)

              // 查询数据情况
              this.userCompare();
            }
          }
        });
      }
      else {
        this.$message.error('请传入所选工资单模板相关的信息');
      }



    },
    file2Xce (file) {
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary',
          });
          const result = [];
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });

          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    getExcelHeaders (file) {
      const getHeaderRow = (sheet) => {
        const headers = []
        // console.log(XLSX.utils.decode_range(sheet['!ref']));
        const range = XLSX.utils.decode_range(sheet['!ref'])
        let C = range.s.c
        for (C = range.s.c; C <= range.e.c; ++C) {
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: 0 })]
          if (cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell))
          }
        }
        return headers
      }
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary',
          });
          resolve(getHeaderRow(this.wb.Sheets.Sheet1 || this.wb.Sheets.sheet1));
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    downExcel () {
      window.location.href = '/web/template/工资单模板.xlsx';
    },
    async userCompare () {
      const res = await salaryRegistrationCompare({
        data: this.submitTransData,
      });

      this.tableData = res.data.map((item) => {
        const t = {
          [this.templateDetail.name]: item['username'],
          [this.templateDetail.idCardNumber]: item['idCardNumber'],
          [this.templateDetail.salary]: item['salary'],
        }
        const unpick = _.without(Object.keys(item), this.templateDetail.name, this.templateDetail.idCardNumber, this.templateDetail.salary)

        unpick.forEach(n => t[n] = item[n])
        return t
      }
      );

      this.valid = res.valid;
      console.log(this.tableData)

    },
  },
};
</script>

<style lang="scss" scoped>
.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}
.header {
  display: flex;
  justify-content: space-between;
  border: none;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  .header_top {
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 20px;
      height: 17px;
    }
    img:nth-child(2) {
      width: 9px;
      height: 10px;
    }
    span {
      margin: 0 10px;
      font-size: 16px;
      color: #606060;
    }
  }
}
.add_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.el-form-item__label {
  padding-bottom: 0 !important;
}
.inputNumberContent {
  background: cornflowerblue !important;
}
</style>
